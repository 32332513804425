import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { appendSlashToUrl } from '../helpers/general';
// import { set } from 'lodash';
// import { get } from 'lodash';

const StoryblokContext = createContext();

export const StoryblokProvider = ({ children }) => {
    const [headerMenu, setHeaderMenu] = useState([]);
    const [footerMenu, setFooterMenu] = useState([]);
    const [secondaryFooterMenu, setSecondaryFooterMenu] = useState([]);
    const [footNote, setFootNote] = useState('');
    const [footSubImage, setFootSubImage] = useState('');
    const [socialLinks, setSocialLinks] = useState({});
    const [navigationMenu, setNavigationMenu] = useState([]);
    const [companyDetails, setCompanyDetails] = useState({});
    const [salesRepList, setSalesRepList] = useState([]);
    const [colorMap, setColorMap] = useState([]);
    const [accessLevelsList, setAccessLevelsList] = useState([]);
    const [powderList, setPowderList] = useState([]);
    const [customerGroup, setCustomerGroup] = useState([]);
    // const [storeLinks, setStoreLinks] = useState({});
    // const [seoData, setSeoData] = useState({});
    // const [bannerLinks, setBannerLinks] = useState('');

    const storyblokData = useStaticQuery(graphql`
        query {
            siteConfig: allStoryblokEntry(filter: {full_slug: {regex: "/global/"}}) {
                edges {
                node {
                    slug
                    uuid
                    field_component
                    full_slug
                    name
                    content
                    }
                }
            }
        }
    `);

    const fetchData = useCallback(() => {

        if(storyblokData && storyblokData.siteConfig && storyblokData.siteConfig.edges.length > 0) {
            const flatData = storyblokData.siteConfig.edges.map(({ node }) => node);

            const footerRawData = flatData.find(item => item.name === 'footer');
            const footerData = footerRawData ? JSON.parse(footerRawData.content) : {};

            const footerLinks = footerData.footerLinks.map((linkGroup) => {
                return {
                    subTitle: linkGroup.name,
                    links: linkGroup.links.map((link) => {
                        return {
                            text: link.Label,
                            link: link.Link?.url,
                        }
                    })
                }
            })

            let secondaryFooterMenu = [];
            if(footerData.policyLinks && footerData.policyLinks.length > 0) {
                const policyLinks = footerData.policyLinks[0] // single group
                secondaryFooterMenu = {
                    subTitle: policyLinks.name,
                    links: policyLinks.links.map((link) => {
                        return {
                            text: link.Label,
                            link: link.Link?.url === "" ? `/${link.Link?.cached_url}` : link.Link?.url,
                        }
                    })
                }
            }


            setFootNote(footerData.footNote);
            setFootSubImage(footerData?.footerSubImage?.filename || '')
            setFooterMenu(footerLinks);
            setSecondaryFooterMenu(secondaryFooterMenu);

            const socialRawData = flatData.find(item => item.name === 'social');
            const socialData = socialRawData ? JSON.parse(socialRawData.content) : {};

            setSocialLinks({
                instagram: socialData?.instagram ? socialData.instagram.url : '',
                facebook: socialData?.facebook ? socialData.facebook.url : '',
                twitter: socialData?.twitter ? socialData.twitter.url : '',
                linkedin: socialData?.linkedin ? socialData.linkedin.url : '',
            });

            const headerRawData = flatData.filter(item => item.name.includes('header'));
            let headers = [];
            headerRawData.forEach((header) => {
                if (header) {
                    headers.push(JSON.parse(header.content));
                }

            });

            let headerBottomLinks = [];
            if (headers.length) {
                headers.forEach((headerData) => {
                    if(headerData.headerLinks && headerData.headerLinks.length > 0) {
                        const headerLinks = headerData.headerLinks[0] // single group
                        const headerBottomLinksList = headerLinks?.links.map((link) => {
                            const resolvedLink = link.Link?.url === "" ? link.Link?.cached_url : link.Link?.url;
                            return {
                                menuLabel: link.Label,
                                menuLink: appendSlashToUrl(resolvedLink),
                                }
                            });
                        headerBottomLinks.push({
                            customerSegment: headerData.customerSegment,
                            links: headerBottomLinksList
                        });
                    }
                })
            }

            setHeaderMenu(headerBottomLinks);

            let navigationMenu = [];
            if (headers.length) {
                headers.forEach((headerData) => {
                    let navMenu = [];
                    // nested menu on navigation
                    if(headerData.navigation && headerData.navigation.length > 0) {
                        navMenu = headerData.navigation.map((navigationLink) => {

                            // no submenu - directly a link
                            if(navigationLink.navigation.length === 0) {
                                return {
                                    menuLabel: navigationLink.label,
                                    menuLink: navigationLink.link?.url,
                                    category: null,
                                    featured: navigationLink.featured,
                                }
                            } else {
                                const headerGroup = navigationLink.navigation;
                                const submenuList = [];

                                for(let x = 0; x < headerGroup.length; x++) {
                                    const submenu = headerGroup[x];
                                    submenuList.push({
                                        categoryLabel: submenu.name,
                                        submenu: submenu.links.map((navMenuItem) => {
                                            return {
                                                menuLabel: navMenuItem.Label,
                                                menuLink: navMenuItem.Link?.url,
                                            }
                                        })
                                    })
                                }
                                return {
                                    menuLabel: navigationLink.label,
                                    menuLink: navigationLink.link?.url,
                                    category: submenuList,
                                    featured: navigationLink.featured,
                                }
                            }
                        })
                    }
                    navigationMenu.push({
                        customerSegment: headerData.customerSegment,
                        navigation: navMenu
                    });
                });
            }

            setNavigationMenu(navigationMenu);

            const companyRawData = flatData.find(item => item.name === 'company');
            const companyData = companyRawData ? JSON.parse(companyRawData.content) : {};
            setCompanyDetails(companyData);

            const salesRepRawData = flatData.find(item => item.name === 'sales rep');
            const salesRepData = salesRepRawData ? JSON.parse(salesRepRawData.content) : {};
            if(salesRepData.list) {
                setSalesRepList(salesRepData.list);
            }

            const colorRawData = flatData.find(item => item.name === 'color map configuration');
            const colorData = colorRawData ? JSON.parse(colorRawData.content) : {};
            const formattedColorData = colorData.list.map((mapColor) => {
                return {
                    name: mapColor.name,
                    color: mapColor?.color?.color,
                    image: mapColor?.image?.filename
                }
            })
            setColorMap(formattedColorData);

            const accessLevelsRawData =  flatData.find(item => item.name === 'Access Levels');
            const accessLevelsData = accessLevelsRawData ? JSON.parse(accessLevelsRawData.content) : {};
            const formattedAccessLevelsData = accessLevelsData.list.map(line => {
                const items = [];
                for (const [key, value] of Object.entries(line)) {
                    if (key !== '_uid' && key !== 'component') {
                        items.push({[key]: value})
                    }
                }
                return items;
            });
            setAccessLevelsList(formattedAccessLevelsData);

            const powderListRawData = flatData.find(item => item.name === 'Powder Coat List');
            const powderListData = powderListRawData ? JSON.parse(powderListRawData.content) : {};
            const powderList = powderListData?.list?.map((color) => color.name);
            setPowderList(powderList);

            const customerGroupRawData = flatData.find(item => item.name === 'Customer Group List');
            const customerGroupData = customerGroupRawData ? JSON.parse(customerGroupRawData.content) : {};
            const customerGroupList = customerGroupData?.list?.map((group) => group.Name);
            setCustomerGroup(customerGroupList);
        }
        // if (contentfulData && contentfulData.siteConfig.nodes.length > 0) {
        //     setHeaderMenu(get(contentfulData, 'siteConfig.nodes[0].headerMenu.menuItems', []));
        //     setFooterMenu(get(contentfulData, 'siteConfig.nodes[0].footerMenu', []));
        //     setNavigationMenu(get(contentfulData, 'siteConfig.nodes[0].navigationMenu', []));
        //     setSecondaryFooterMenu(get(contentfulData, 'siteConfig.nodes[0].secondaryFooterMenu.menuItems', []));
        //     setSocialLinks({
        //         instagram: get(contentfulData, 'siteConfig.nodes[0].instagramLink', ''),
        //         facebook: get(contentfulData, 'siteConfig.nodes[0].facebookLink', ''),
        //         youTube: get(contentfulData, 'siteConfig.nodes[0].youTubeLink', ''),
        //         tiktok: get(contentfulData, 'siteConfig.nodes[0].tiktokLink', ''),
        //     });
        //     setStoreLinks({
        //       appStore: get(contentfulData, 'siteConfig.nodes[0].appStoreLink', ''),
        //       playStore: get(contentfulData, 'siteConfig.nodes[0].playStoreLink', ''),
        //     })
        //     setBannerLinks(get(contentfulData, 'siteConfig.nodes[0].bannerLinks', ''));
        //     setSeoData({
        //         ogImage: get(contentfulData, 'siteConfig.nodes[0].defaultSocialShareImage.file.url', ''),
        //         metaDescription: get(contentfulData, 'siteConfig.nodes[0].defaultMetaDescription.defaultMetaDescription', ''),
        //         streetAddress: get(contentfulData, 'siteConfig.nodes[0].streetAddress', ''),
        //         suburb: get(contentfulData, 'siteConfig.nodes[0].suburb', ''),
        //         postcode: get(contentfulData, 'siteConfig.nodes[0].postcode', ''),
        //         country: get(contentfulData, 'siteConfig.nodes[0].country', ''),
        //         state: get(contentfulData, 'siteConfig.nodes[0].state', ''),
        //         city: get(contentfulData, 'siteConfig.nodes[0].city', ''),
        //     })
        // }
    }, [storyblokData]);

    useEffect(() => fetchData(), [fetchData]);

    return (
        <StoryblokContext.Provider value={{
            headerMenu,
            footerMenu,
            footNote,
            secondaryFooterMenu,
            socialLinks,
            navigationMenu,
            companyDetails,
            footSubImage,
            salesRepList,
            colorMap,
            accessLevelsList,
            powderList,
            customerGroup,
            // navigationMenu,
            // storeLinks,
            // seoData,
            // bannerLinks,
        }}>{children}</StoryblokContext.Provider>
    );
};

export default StoryblokContext;